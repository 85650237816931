<!-- 详情与编辑、添加都放同一页 -->
<template>
    <div>
        <!-- 基本信息 -->
        <div class="pad-container">
            <p>活动信息</p>
            <el-form class="mt-24" label-width="120px" :model="act_form" :rules="rules" ref="act_form">
                <el-form-item label="活动名称" prop="activityName">
                    <el-input
                            class="form-input"
                            maxlength="15"
                            show-word-limit
                            placeholder="请输入活动标题"
                            v-model="act_form.activityName"
                    ></el-input>
                </el-form-item>
                <el-form-item label="所属门店" prop="storeName">
                    <el-input v-model="act_form.storeName" disabled class="form-input"></el-input>
                </el-form-item>
                <el-form-item label="活动头图" prop="picture">
                    <!-- 简化场景，用原生的，需求：超市海报图过大，切割分为多图 -->
                    <!-- <div class="addPic buttons_sure" @click="addMorePic()">增加</div> -->
                    <!-- <div class="active_header_pic"> -->
                      <!-- <div v-for='(itemPic,index) in act_form.picture' :key="index" class="pic_item"> -->
                      <!-- <UploadPics
                            :uploadType="'main'"
                            :imgLimit="99"
                            :listType="'picture'"
                            :imgSize="'750'"
                            :imgsPreviewList ="fileList"
                            @getImgData="getImgData"
                      /> -->
                      <!-- <div class="addPic buttons_cancle"  @click="deletePic(index)">删除</div> -->
                    <!-- </div> -->
                    <!-- </div> -->
                    <el-upload
                      class="upload-demo"
                      :action='picsPath()'
                      :on-preview="handlePreview"
                      :on-remove="handleRemove"
                      :file-list="fileList"
                      list-type="picture"
                      :show-file-list="false"
                      :before-upload ="beforeUpLoad"
                      :on-success ="uploadSuccess">
                      <el-button size="small" type="primary" v-loading="loading">点击上传图片<i class="el-icon-upload2"></i></el-button>
                      <div slot="tip" class="el-upload__tip">配置商超海报图专区时可上传多张图片，其他专区活动请不要超过一张主图</div>
                    </el-upload>
                    <div class="imgListBox" v-loading="loading">
                        <div class="imgList" v-for="(item,index) in imgurlList" :key="index">
                            <img :src="picsPath()+item"/>
                            <div class="picDelete" @click="deletePic(index)"><i class="el-icon-plus"></i></div>
                        </div>
                    </div>
                </el-form-item>
                
                <el-form-item>
                    <el-button icon="el-icon-plus" type="primary" @click="addClassify">添加分类</el-button>
                    <div
                            class="pad-container mt-20 ps-re"
                            v-for="(item,index) in act_form.activityClassifies"
                            :key="index"
                    >
                        <el-button
                                type="primary"
                                icon="el-icon-delete"
                                class="del-btn"
                                @click="delClassify(index)"
                        >删除</el-button>
                        <el-form-item
                                label="分类标题"
                                :prop="'activityClassifies['+index+'].activityClassifyName'"
                                class="mb-10"
                                :rules="[{required:true,trigger:'blur',message:'分类标题不能为空'}]"
                        >
                            <el-input
                                    class="form-input"
                                    maxlength="15"
                                    show-word-limit
                                    placeholder="请输入分类标题"
                                    v-model="item.activityClassifyName"
                            ></el-input>
                        </el-form-item>
                        <el-form-item
                                label="分类标题图"
                                :prop="'activityClassifies['+index+'].picture'"
                                :rules="[{required:true,trigger:'blur',message:'分类标题图不能为空'}]"
                        >
                            <UploadPics
                                    :uploadType="'main'"
                                    :imgLimit="1"
                                    :imgSize="'750*200'"
                                    :imgIndex="index"
                                    :imgsPreview="item.picture"
                                    @getImgData="getImgData"
                            />
                        </el-form-item>
                        <el-form-item
                                label="活动商品"
                                :prop="'activityClassifies['+index+'].activityRelations'"
                                :rules="[{required:true,trigger:'blur',message:'活动商品不能为空'}]"
                        >
                            <div>
                                <el-button
                                        type="primary"
                                        plain
                                        @click="selectGoods(item.activityRelations,index)"
                                >{{item.activityRelations&&item.activityRelations.length>=1?'重新选择商品':'添加商品'}}</el-button>
                            </div>
                            <el-table
                                    class="mt-24 member-table"
                                    :data="item.activityRelations"
                                    width="70%"
                                    v-if="item.activityRelations.length>0"
                            >
                                <el-table-column label="商品条形码">
                                    <template slot-scope="scope">
                                        <span>{{scope.row.goodsCode}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="商品名称">
                                    <template slot-scope="scope">
                                        <span>{{scope.row.goodsName}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="一级类目">
                                    <template slot-scope="scope">
                                        <span>{{scope.row.classifyName}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="二级类目">
                                    <template slot-scope="scope">
                                        <span>{{scope.row.secondClassifyName}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="实价">
                                    <template slot-scope="scope">
                                        <span>{{scope.row.actualPrice}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="库存">
                                    <template slot-scope="scope">
                                        <span>{{scope.row.stock}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="排序">
                                    <template slot-scope="scope">
                                        <el-input v-model="scope.row.sort" placeholder="请输入内容"></el-input>
                                    </template>
                                </el-table-column>
                                <el-table-column label="操作">
                                    <template slot-scope="scope">
                                        <div class="flx-row ali-c">
                      <span
                              class="ml-10 tip-text-delete"
                              @click="deleteGoods(index,scope.$index)"
                      >删除</span>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-form-item>
                    </div>
                </el-form-item>
            </el-form>
            <div class="flx-row ali-c js-fe mt-50">
                <el-button plain class="form-button" @click="cancel">取消</el-button>
                <el-button class="form-button" type="primary" @click="submit">确定</el-button>
            </div>
        </div>
        <GoodsDialog
                ref="GoodsDialog"
                :tableHeader="tableHeader"
                :hasTab="true"
                :tabList="tabList"
                :selected="true"
                :storeId="storeId"
                :searchArr="searchArr"
                @getSelectedList="getSelectedList"
        ></GoodsDialog>
    </div>
</template>
<script>
    import UploadPics from "@/components/UploadPics";
    import GoodsDialog from "@/components/GoodsDialog";
    import {
        addActivitySection,
        getSectionDetail,
    } from "@/api/market/activity";

    export default {
        components: {
            UploadPics,
            GoodsDialog
        },
        data() {
            return {
                radio: 1,
                searchArr: [
                    {
                        name: "goodsCodes",
                        value: "",
                        placeholder: "请输入商品条形码"
                    },
                    {
                        name: "goodsName",
                        value: "",
                        placeholder: "请输入商品名称"
                    }
                ],
                tabList: [
                    {
                        label: "商超商品",
                        name: "1",
                        url: "/shd-operation/lrGoodsInfo/queryGoodsInfo",
                        method: "post",
                        key: "goodsId",
                        type: "1"
                    },
                    {
                        label: "生鲜商品",
                        name: "2",
                        url: "/shd-operation/lrGoodsInfo/queryGoodsInfo",
                        method: "post",
                        key: "goodsId",
                        type: "2"
                    }
                ],
                goodsUrl: "/goods-service/goods/info/list", // 商品列表url
                shopOptions: [],
                act_form: {
                    storeName: "",
                    activityName: "",
                    picture:'',
                    activityClassifies: [
                        {
                            activityClassifyName: "",
                            picture: "",
                            activityRelations: []
                        },
                        {
                            activityClassifyName: "",
                            picture: "",
                            activityRelations: []
                        }
                    ]
                },
                rules: {
                    activityName: [
                        { required: true, message: "活动名称不能为空", trigger: "blur" }
                    ],
                    picture: [
                        { required: true, message: "活动图片不能为空", trigger: "blur" }
                    ],
                    activityRelations: [
                        { required: true, message: "拼团商品不能为空", trigger: "blur" }
                    ]
                },
                actId: "",
                total: 0,
                pageNum: 1,
                pageSize: 5,
                pageSizes: [5, 8, 10, 15],
                mainImgStr: "",
                firstFlag: false,
                id: null,
                selected_temp: [], // 全局勾选数组
                // 商品列表表头
                tableHeader: [
                    {
                        value: "商品条形码",
                        key: "goodsCode"
                    },
                    {
                        value: "商品名称",
                        key: "goodsName"
                    },
                    {
                        value: "一级类目",
                        key: "classifyName"
                    },
                    {
                        value: "二级类目",
                        key: "secondClassifyName"
                    },
                    {
                        value: "实价",
                        key: "actualPrice"
                    },
                    {
                        value: "库存",
                        key: "stock"
                    }
                ],
                storeId: "",
                fileList:[],
                imgurlList:[],
                loading:false            
                };
        },
        mounted() {
            this.init();
        },
        filters: {
            goodsTypeFilter(val) {
                switch (val) {
                    case 1:
                        return "商品";
                    case 2:
                        return "优惠券";
                    case 3:
                        return "商兑换券";
                    default:
                        return "--";
                }
            }
        },
        methods: {
            // 删除分类
            delClassify(index) {
                this.isConfirm(this, () => {
                    this.act_form.activityClassifies.splice(index, 1);
                });
            },
            // 添加分类
            addClassify() {
                let item = {
                    activityClassifyName: "",
                    picture: "",
                    activityRelations: []
                };
                this.act_form.activityClassifies.push(item);
            },
            //   获取已勾选商品
            getSelectedList(data, index) {
                this.act_form.activityClassifies[index].activityRelations = data;
                this.$set(
                    this.act_form.activityClassifies,
                    index,
                    this.act_form.activityClassifies[index]
                );
                // console.log(this.act_form.activityClassifies);
            },
            // 打开商品列表弹窗
            selectGoods(activityRelations, index) {
                // console.log(activityRelations, "list");
                let data = this.$refs.GoodsDialog;
                data.act_form.list = activityRelations;
                data.showGoods = true;
                data.index = index;
                data.getGoods(true);
                data.selected_temp = JSON.parse(JSON.stringify(activityRelations));
            },
            // 获取详情id
            init() {
                // console.log(this.$route.query);
                let query = this.$route.query;
                this.actId = query.id || "";
                this.storeId = query.storeId;
                this.actId ? this.getActData() : "";
                this.act_form.storeName = this.$route.query.storeName;
            },
            //获取活动详情
            getActData() {
                getSectionDetail({ id: this.actId }).then(res => {
                    let act_data = res.data.body;
                    // act_data.picture = act_data.picture.split(',')//图片路径字符转数组，用于海报页面传多图
                    this.act_form = act_data;
                    this.act_form.activityClassifies.forEach(item => {
                        item.activityRelations.forEach(val => {
                            val.goodsName = val.goodsDetail.goodsName;
                            val.goodsCode = val.goodsDetail.goodsCode;
                            val.actualPrice = val.goodsDetail.actualPrice;
                            val.stock = val.goodsDetail.stock;
                            val.classifyName = val.goodsDetail.classifyName;
                            val.secondClassifyName = val.goodsDetail.secondClassifyName;
                        });
                    });
                    this.imgurlList = this.act_form.picture.split(",")
                    console.log(this.act_form)
                    // this.mainImgStr = act_data.picture;
                });
            },
            // 删除已选商品
            deleteGoods(index, idx) {
                this.act_form.activityClassifies[index].activityRelations.splice(idx, 1);
                this.$refs.GoodsDialog.selected_temp = JSON.parse(
                    JSON.stringify(
                        this.act_form.activityClassifies[index].activityRelations
                    )
                );
            },
            // 获取图片数据
            getImgData(imgData, type, params, index) {
                console.log(imgData, type, params, index);
                if (index) {
                    console.log("2");
                    this.act_form.activityClassifies[index].picture = imgData.mainImg;
                } else if (index == 0) {
                    console.log("21");
                    
                    this.act_form.activityClassifies[0].picture = imgData.mainImg;
                }
                console.log(this.act_form.activityClassifies)
            },
            // 点击上传图片列表的钩子
            handlePreview(file){
              console.log(file,this.fileList)
            },
            // 移除列表中图片的钩子
            handleRemove(file,fileList){
              console.log(file,fileList)
            },
            // 上传成功的钩子
            uploadSuccess(response,file,fileList){
                console.log(response)
                console.log(file)
                console.log(fileList)
                this.imgurlList.push(response.body)
                // let index = fileList.length-1
                // this.fileList[index].name = file.name
                // this.fileList[index].url = this.picsPath()+response.body
                // this.fileList[index].uid = index
                // console.log("看看上传成功的文件列表",this.fileList)
                console.log("图片列表字符串",this.imgurlList)
                console.log("转移字符串",this.imgurlList.join(","))
                console.log("")
                this.loading = false
            },
            beforeUpLoad(file){
                console.log(file.size)
                let _this = this
                _this.loading = true
                return new Promise((resolve, reject) => {
                    // 小于200KB和PNG图片不做处理
                    let isLt2M = ((file.size / 1024)< 200) || file.type == "image/png"
                    if(isLt2M) {
                        console.log("执行")
                        resolve(file)
                        return
                    }
                    let image = new Image(), resultBlob = '';
                    image.src = URL.createObjectURL(file);
                    image.onload = () => {
                        // 进行canvas压缩
                        resultBlob = _this.compressUpload(image, file);
                        resolve(resultBlob)
                    }
                    image.onerror = () => {
                       reject()
                    }
                })
            },
            /* 图片压缩方法-canvas压缩 */
            compressUpload(image, file) {
                let canvas = document.createElement('canvas')
                let ctx = canvas.getContext('2d')
                let initSize = image.src.length
                let { width } = image, { height } = image
                canvas. width = width
                canvas.height = height
                ctx.fillRect(0, 0, canvas.width, canvas.height)
                ctx.drawImage(image, 0, 0, width, height)
                // 进行最小压缩0.1
                let compressData = canvas.toDataURL(file.type || 'image/jpeg', 0.9)
                // 压缩后调用方法进行base64转Blob，方法写在下边
                let blobImg = this.dataURItoBlob(compressData)
                console.log(blobImg.size)
                return blobImg
            },
            /* base64转Blob对象 */
            dataURItoBlob(data) {
                let byteString;
               if(data.split(',')[0].indexOf('base64') >= 0) {
                   byteString = atob(data.split(',')[1])
               }else {
                   byteString = unescape(data.split(',')[1])
               }
              let mimeString = data
                  .split(',')[0]
                  .split(':')[1]
                  .split(';')[0];
              let ia = new Uint8Array(byteString.length)
              for( let i = 0; i < byteString.length; i += 1) {
                  ia[i] = byteString.charCodeAt(i)
               }
               return new Blob([ia], {type: mimeString})
            },
            // 返回
            cancel() {
                this.$router.go(-1);
            },
            // 提交表单-验证
            submit() {
                this.act_form.picture = this.imgurlList.join(",")
                console.log(this.act_form)
                this.$refs["act_form"].validate(valid => {
                    if (!valid) {
                        this.$message.warning("表单信息不完整（有误）");
                        return;
                    }
                    this.submitForm();
                });
            },
            // 提交表单-提交
            submitForm() {
                // let method = "post";
                let data = JSON.parse(JSON.stringify(this.act_form));
                if (data.activityRelations && data.activityRelations.length > 0) {
                    data.activityRelations.forEach(item => {
                        delete item.goodsInfo;
                        delete item.groupId;
                        delete item.id;
                    });
                }
                data.storeId = this.storeId;
                if (this.actId) {
                    data.id = this.actId;
                }
                addActivitySection(data).then(() => {
                    this.$message.success("操作成功");
                    this.$router.go(-1);
                });
            },
            //海报专区需要多张图片
            addMorePic(){
                this.act_form.picture.push("")
                console.log(this.act_form.picture)
            },
            deletePic(e){
                console.log(e)
                let tempArr = JSON.parse(JSON.stringify(this.imgurlList))  
                tempArr.splice(e,1)
                this.imgurlList = JSON.parse(JSON.stringify(tempArr))
                console.log(this.imgurlList)
            }
        }
    };
</script>
<style lang="scss" scoped>
    .tip-text-info {
        color: #409eff;
        cursor: pointer;
    }
    .el-avatar /deep/ img {
        width: 100%;
    }
    .style {
        width: 130px;
        height: 200px;
        background: #f1f1f1;
        padding: 10px;
        box-sizing: border-box;
        display: inline-block;
        vertical-align: top;
        div {
            border: 1px solid #ccc;
            float: left;
            margin-bottom: 10px;
            background: #fff;
        }
    }
    .style1 div {
        width: 50px;
        height: 80px;
        &:nth-child(even) {
            margin-left: 10px;
        }
    }
    .style2 div {
        width: 30px;
        height: 50px;
        margin-left: 5px;
    }
    .flx-row /deep/ .el-radio {
        margin-right: 10px;
    }
    .el-form-item /deep/ .el-form-item {
        margin-bottom: 20px;
    }
    .del-btn {
        position: absolute;
        right: 20px;
        top: 20px;
        z-index: 10;
    }
    .imgListBox{
        width: 100%;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        align-items: flex-start;
        .imgList{
            width: 200px;
            height: 200px;
            position: relative;
            overflow: hidden;
            img{
                width: 100%;
                height: 100%;
            }
            .picDelete{
                display: block;
                position:absolute;
                top: -6px;
                right: -30px;
                width: 80px;
                height: 40px;
                font: 16px;
                line-height: 40px;
                text-align: center;
                transform: rotate(45deg);   
                background-color: red;
                color: white;
                box-shadow:0 1px 1px #ccc;
                .el-icon-plus{
                    display: block;
                    width: 16px;
                    height: 16px;
                    font-size: 16px;
                    line-height: 16px;
                    margin: 15px 0 0 30px;
                }
            }
        }
    }
    .el-icon-upload2{
        font-size: 14px;
    }
</style>
