import request from '@/utils/request'

export function getActivityList (data) {
    return request({
        url: '/goods-service/lrGoodsActivity/queryAllGoodsActivity',
        method: 'post',
        data
    })
}

export function getSectionDetail (params) {
    return request({
        url: '/goods-service/lrGoodsActivity/queryActivitySectionByIdBackstage',
        method: 'get',
        params
    })
}

export function getActivityDetail (params) {
    return request({
        url: '/goods-service/lrGoodsActivity/queryGoodsActivityById',
        method: 'get',
        params
    })
}
export function getActivityDetailBack (params) {
    return request({
        url: '/goods-service/lrGoodsActivity/queryGoodsActivityByIdBack',
        method: 'get',
        params
    })
}

export function addActivitySection (data) {
    return request({
        url: '/goods-service/lrGoodsActivity/saveActivitySection',
        method: 'post',
        data
    })
}

export function addActivity (data) {
    return request({
        url: '/goods-service/lrGoodsActivity/saveGoodsActivity',
        method: 'post',
        data
    })
}

export function upAndDownActivity (data) {
    return request({
        url: `/goods-service/lrGoodsActivity/upAndDownActivity?id=${data.id}&status=${data.status}`,
        method: 'post'
    })
}

export function delActivty (params) {
    return request({
        url: '/goods-service/lrGoodsActivity/delGoodsActivity',
        method: 'delete',
        params
    })
}

export function getProductsList (data) {
    return request({
        url: '/shd-operation/lrGoodsInfo/queryGoodsInfo',
        method: 'post',
        data
    })
}

//选择互斥活动
export function getMutrx(data){
    return request({
        url:'/goods-service/lrGoodsActivity/queryMutex',
        method: 'post',
        data
    })
}

export function importGoods(){
    return request({
        url:'/goods-service/lrGoodsActivity/importExcel',
        method: 'post',
        data
    })
}
